$main-color: #F2F2F2;

body {
  background-color: $main-color;
  
}

.hodge-gpt-background {
  font-family: "ui-sans-serif, system-ui,-apple-system,Segoe UI", sans-serif;
  font-variation-settings: normal;
  margin: 20px;
  }

.hodge-gpt-chat {
  margin: auto;
  max-width: 550px;
  min-height: 280px;
  border: .1rem solid black;
  border-radius: 30px;
  padding: 10px;

}

.hodge-gpt-heading {
  text-align: center;
}

h3.hodge-gpt-heading {
  margin: 0px;
}

.hodge-gpt-form {
  width: 100%;

}

textarea.hodge-gpt-input-question {
    width: 100%;
    height: 80px;
    max-width: 1000px;
    font-size: medium;
    font-family: inherit;
    font-style: italic;
    color: rgb(104, 104, 104);
    padding: 5px;
    resize: none;
    border: .1rem solid rgba(104, 104, 104, 0.712);
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }  

button.hodge-gpt-btn-ask {
  background-color: black;
  font-family: inherit;
  color: #F2F2F2;
  font-size: medium;
  border: 0px solid black;
  border-radius: 4px;
  height: 35px;
  width: 60px;
  text-align: center;
  margin-top: 10px;
  float: right;

}


.hodge-gpt-answer {
  margin: auto;
  max-width: 550px;
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 40px;

}

.hodge-gpt-answer p {
  //letter-spacing: .1em;
  font-size: large;
  animation: fadeIn 4s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

